import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Container from "./container";
import { Link } from "gatsby";
import { getInitiativeUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/initiative-list.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query InitiativesQuery {
    initiatives: allSanityInitiative(
      sort: { fields: [order], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          listImage {
            ...SanityImage
            alt
          }
          featuredImage {
            ...SanityImage
            alt
          }
          excerpt
        }
      }
    }
  }
`;

const InitiativeList = (props) => {
  return (
    <StaticQuery
    query={query}
    render={data => (
      <div className={styles.root}>
        <Container>
          {data && (
            <ul>
              {data.initiatives.edges.map(({node: initiative}) => (
                <li key={initiative.id} className={styles.item}>
                  {((initiative.listImage && initiative.listImage.asset) || (initiative.featuredImage && initiative.featuredImage.asset)) && (
                    <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                      <Link className={styles.link} to={getInitiativeUrl(initiative.slug.current)}>
                        <picture>
                          {initiative.featuredImage && initiative.featuredImage.asset && initiative.listImage && initiative.listImage.asset && (
                            <>
                            <source media="(max-width: 959px)" 
                              srcset={imageUrlFor(buildImageObj(initiative.featuredImage))
                                .auto("format")
                                .url()} 
                            />
                            <source media="(min-width: 960px)" srcset={imageUrlFor(buildImageObj(initiative.listImage))
                              .auto("format")
                              .url()} 
                            />
                            </>
                          )}
                          {initiative.listImage && initiative.listImage.asset && (
                            <img
                              src={imageUrlFor(buildImageObj(initiative.listImage))
                              .auto("format")
                              .url()}
                              alt={initiative.listImage.alt}
                            />
                          )}
                          {(!initiative.listImage || !initiative.listImage.asset) && (initiative.featuredImage && initiative.featuredImage.asset) && (
                            <img
                              src={imageUrlFor(buildImageObj(initiative.featuredImage))
                              .auto("format")
                              .url()}
                              alt={initiative.featuredImage.alt}
                            />
                          )}
                        </picture>
                      </Link>
                    </div>
                  )}
                  {(initiative.title || initiative.excerpt || initiative.slug) && (
                    <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                      <div className="containerContent">
                        {initiative.title && <h2><Link className={styles.link} to={getInitiativeUrl(initiative.slug.current)}>{initiative.title}</Link></h2>}
                        {initiative.excerpt && <p>{initiative.excerpt}</p>}
                        <Link className="btn" to={getInitiativeUrl(initiative.slug.current)}>Learn More</Link>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </Container>
      </div>
    )} />
  );
}

export default InitiativeList;
