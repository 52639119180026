import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/partner-list.module.css";

function PartnerList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ _key, name, description, websiteUrl, image }) => {
        return (
          <li key={_key} className={styles.item} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
            {((image && image.asset) || name) && (
              <div className={styles.image}>
                {websiteUrl ? (
                  <a href={websiteUrl} target="_blank" rel="noreferrer">
                    {image && image.asset && (
                      <div className={styles.imageWrap}>
                        <img
                          src={imageUrlFor(buildImageObj(image))
                            .auto("format")
                            .url()}
                          alt={image.alt}
                        />
                      </div>
                    )}
                    {name && <h3>{name}</h3>}
                  </a>
                ) : (
                  <>
                  {image && image.asset && (
                    <div className={styles.imageWrap}>
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .auto("format")
                          .url()}
                        alt={image.alt}
                      />
                    </div>
                  )}
                  {name && <h3>{name}</h3>}
                  </>
                )}
              </div>
            )}
            {(description || websiteUrl) && (
              <div className={styles.text}>
                {description && <p>{description}</p>}
                {websiteUrl && <a href={websiteUrl} className="linkExternal" target="_blank" rel="noreferrer">Visit Website<Icon symbol="external" /></a>}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default PartnerList;
