import React from "react";
import Container from "./container";
import PartnerList from "./partner-list";
import PortableText from "./portableText";

import * as styles from "../styles/components/initiatives-partners.module.css";

const Partners = (props) => {

  const {
    heading,
    _rawDescription,
    partners
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className="containerContent" data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          {heading && <h2 className="h3">{heading}</h2>}
          {_rawDescription && <PortableText blocks={_rawDescription} />}
        </div>
        {partners && <PartnerList items={partners} />}
      </Container>
    </div>
  );
}

export default Partners;
