import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import Helmet from 'react-helmet';

import PageBanner from "../components/page-banner";
import FeaturedBlocks from "../components/featured-blocks";
import InitiativeList from "../components/initiative-list";
import Partners from "../components/initiatives-partners";
import NextPage from "../components/next-page";

import * as styles from "../styles/components/initiatives.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query InitiativesPageQuery {
    initiatives: sanityInitiatives {
      pageBanner {
        text
        anchorLinkText
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
      }
      featuredBlocks {
        _key
        _rawText
        rightAligned
        featuredImage
        image {
          ...SanityImage
          alt
          caption
        }
        link {
          text
          linkUrl
        }
      }
      InitiativesPartners {
        heading
        _rawDescription
        partners {
          _key
          name
          description
          websiteUrl
          image {
            ...SanityImage
            alt
          }
        }
      }
      nextPage {
        link {
          linkUrl
          text
        }
        _rawDescription
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
      }
      description
    }
  }
`;

const InitiativesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const initiatives = (data || {}).initiatives;

  return (
    <Layout>
      <Helmet>
        <body className={styles.root} />
      </Helmet>
      <Seo
        title={initiatives.pageBanner.text}
        description={initiatives.description}
      />

      {initiatives.pageBanner && <PageBanner {...initiatives.pageBanner} />}

      <div id="main">

        {initiatives.featuredBlocks && <FeaturedBlocks items={initiatives.featuredBlocks} />}

        <InitiativeList />

        {initiatives.InitiativesPartners && <Partners {...initiatives.InitiativesPartners} />}

        {initiatives.nextPage && <NextPage {...initiatives.nextPage} />}

      </div>
    </Layout>
  );
};

export default InitiativesPage;
