import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import Container from "./container";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/featured-blocks.module.css";

function FeaturedBlocks({ items }) {
  return (
    <div className={styles.root}>
      <Container>
        {items.map(({ _key, _rawText, rightAligned, featuredImage, image, link }) => {
          return (
            <div className={styles.item} key={_key} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              <div className={cn(styles.grid, (rightAligned && (!image || !_rawText)) && styles.right)}>
                {image && image.asset && (
                  <div className={cn(styles.image, !_rawText && styles.imageWrap, featuredImage && !_rawText && styles.featuredImage)}>
                    <figure>
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .auto("format")
                          .url()}
                        alt={image.alt}
                      />
                      {image.caption && <figcaption>{image.caption}</figcaption>}
                    </figure>
                  </div>
                )}
                {(_rawText || (link && link.linkUrl)) && (
                  <div className={cn(styles.text, !image && styles.textWrap)}>
                    {_rawText && <PortableText blocks={_rawText} />}
                    {link && link.linkUrl && <Link to={link.linkUrl} className="linkArrowRight">{link.text}<Icon symbol="arrow-right" /></Link>}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Container>
    </div>
  );
}

export default FeaturedBlocks;
